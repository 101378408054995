
/* Working Section */

.working-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px auto;
    background-color: #1B1C3B;
    border-radius: 30px;
    width: 90%;
    height: 500px;
    padding: 20px;
    position: relative;
  }
  
  .working-section span {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 50px;
  }
  
  .working-section h3 {
    font-size: 20px;
    color: #E84038;
    margin-bottom: 15px;
    width: 30%;
  }
  
  .working-section h2 {
    font-size: 38px;
    color: #F5F5F5;
    margin-bottom: 20px;
    width: 70%;
  }
  
  .working-section h5 {
    width: 90px;
    font-size: 16px;
    color: #c7c5c5;
    margin: 20px 0px;
    border-bottom: 1px solid #c7c5c5;
  }
  
  .working-section p {
    font-size: 20px;
    color: #c7c5c5;
    line-height: 1.6;
    width: 70%;
  }
  
  .working-section-steps {
    width: 50%;
    height: auto;
    padding: 20px;
  }
  
  .working-section-steps h3 {
    font-size: 50px;
    color: #E84038;
    margin-bottom: 15px;
    width: 10%;
  }
  
  .working-section-image {
    position: absolute;
    bottom: 55px;
    right: 100px;
    width: 84px;
    height: auto;
  }
  
  .working-section-steps p {
    font-size: 48px;
    color: #c7c5c5;
    line-height: 1.6;
    width: 100%;
  }
  

  /* Media Queries for Working Section */

@media (max-width: 1200px) {
    .working-section {
      flex-direction: column;
      height: 800px;
    }
  
    .working-section span {
      width: 80%;
      padding: 20px;
    }
  
    .working-section-steps img {
      width: 20%;
      position: relative;
      bottom: 70px;
      left: 570px;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 1024px) {
    .working-section {
      flex-direction: column;
      height: 800px;
    }
  
    .working-section span {
      width: 80%;
      padding: 20px;
    }
  
    .working-section-steps img {
      width: 20%;
      position: relative;
      bottom: 70px;
      left: 480px;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .working-section {
      height: auto;
      margin: 30px auto;
    }
  
    .working-section-steps h3, .working-section-steps p {
      font-size: 20px;
    }
  
    /* .working-section-image {
      display: none;
    } */
  
    .working-section span {
      width: 100%;
      padding: 10px;
    }
  
    .working-section-steps img {
      width: 30%;
      position: relative;
      bottom: 23px;
      left: 325px;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .working-section span, .working-section-steps {
      width: 100%;
      /* padding: 10px; */
    }
  
    .working-section-steps img {
      /* width: 30%;
      position: relative;
      bottom: 0px;
      left: 130px;
      margin-top: 20px; */
      display: none;
    }
  
    .working-section h3, .working-section h2, .working-section p {
      width: 100%;
      font-size: 18px;
    }
    .working-section-steps h3 {
      width: 17%;
    }
    .working-section-steps h3, .working-section-steps p {
      font-size: 16px;
    }
  }
  