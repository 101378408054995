/* Divider Styling */
.custom-divider {
    position: relative;
    top: 5px;
    right: 5px;
    border: 1px solid #E84038;
    margin: 20px 0px 40px 0px;
    width: 100%; /* Make it responsive */
  }
  
  @media (max-width: 768px) {
    .custom-divider {
      width: 100%; /* Full width on smaller screens */
      margin: 20px 0; /* Adjust margins */
    }
  }
  
  @media (max-width: 480px) {
    .custom-divider {
      width: 100%; /* Ensure full width on extra-small screens */
      margin: 20px 0; /* Adjust margins further if needed */
    }
  }
  