/* FAQ Section */
.faq {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    background-color: #F0F4F8;
    gap: 40px;
    align-items: flex-start;
  }
  
  .faq-texts {
    width: 40%;
  }
  
  .faq h3 {
    font-size: 18px;
    color: #E84038;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .faq h2 {
    font-size: 42px;
    color: #21253b;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  
  .faq p {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .faq-link {
    width: 200px;
    font-size: 18px;
    color: #555;
    cursor: pointer;
    /* border-bottom: 1px solid #555; */
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    padding-bottom: 2px;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  
  .faq-link:hover {
    color: #E84038;
    border-color: #E84038;
  }
  
  /* Customize Ant Design Collapse */
  .custom-collapse {
    width: 50%;
    background-color: transparent;
  }
  
  .custom-panel {
    border: none;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .custom-panel .ant-collapse-header {
    background-color: #F5F5F5;
    color: #1B1C3B;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 20px;
    transition: background-color 0.3s ease;
  }
  
  .custom-panel .ant-collapse-header:hover {
    background-color: #4E2A84;
    color: #F5F5F5;
  }
  
  .custom-panel .ant-collapse-content {
    background-color: #4E2A84 !important;
    border-radius: 5px;
  }
  
  .custom-panel .ant-collapse-content p {
    color: #F5F5F5;
    font-size: 16px;
    padding: 20px;
  }
  
  .custom-icon-container {
    background-color: #ec2446;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .custom-icon-container.active {
    transform: rotate(90deg);
  }
  
  .custom-icon {
    color: #EBF7FE;
    font-size: 16px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .faq {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .faq-texts,
    .custom-collapse {
      width: 100%;
    }
  
    .faq h2 {
      font-size: 36px;
      text-align: center;
    }
  
    .faq h3,
    .faq p,
    .faq-link {
      text-align: center;
    }
  
    .faq h3 {
      font-size: 16px;
    }
  
    .faq p {
      font-size: 16px;
    }
  
    .faq-link {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    .faq {
      padding: 15px;
    }
  
    .faq h2 {
      font-size: 32px;
    }
  
    .faq h3 {
      font-size: 14px;
    }
  
    .faq p {
      font-size: 14px;
    }
  
    .faq-link {
      font-size: 14px;
    }
  
    .custom-panel .ant-collapse-header {
      font-size: 16px;
      padding: 10px 15px;
    }
  
    .custom-panel .ant-collapse-content p {
      font-size: 14px;
      padding: 15px;
    }
  
    .custom-icon-container {
      width: 25px;
      height: 25px;
    }
  
    .custom-icon {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .faq h2 {
      font-size: 28px;
    }
  
    .faq h3 {
      font-size: 12px;
    }
  
    .faq p {
      font-size: 12px;
    }
  
    .faq-link {
      font-size: 12px;
    }
  
    .custom-panel .ant-collapse-header {
      font-size: 14px;
      padding: 8px 12px;
    }
  
    .custom-panel .ant-collapse-content p {
      font-size: 12px;
      padding: 10px;
    }
  
    .custom-icon-container {
      width: 20px;
      height: 20px;
    }
  
    .custom-icon {
      font-size: 12px;
    }
  }
  