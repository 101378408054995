.hero-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #392779; /* Matching the purple background */
    padding: 60px;
    margin: 60px auto;
    width: 90%;
    border-radius: 20px;
    color: #fff;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  .hero-content {
    max-width: 500px;
    z-index: 1; /* Ensure content is above the background image */
  }
  
  .hero-title {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 16px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.7); /* Light grey color for subtitle text */
    margin-bottom: 30px;
  }
  
  .hero-button {
    background-color: #E84038; /* Matching the red button */
    border-color: #E84038;
    height: 50px;
    width: 150px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .hero-button:hover {
    background-color: #392779 !important; /* Matching the red button */
    border-color: #E84038 !important;
  }
  
  .hero-image {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 400px;
    z-index: 0;
  }
  
  .logo-background {
    position: absolute;
    width: 600px;
    height: 600px;
    background-image: url("../../assets/logo2.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(-25deg);
    opacity: 0.5;
    z-index: -1; /* Place background behind the content */
    right: 0px;
  }
  
  /* Media Queries for Responsiveness */

  @media (max-width: 1200px) {
    .hero-section {
      padding: 50px; /* Adjust padding for mid-sized screens */
      text-align: left; /* Center-align text */
      margin: 30px auto; /* Reduce margin for better spacing */
      width: 100%; /* Adjust width to fit better on mid-sized screens */
    }
  
    .hero-title {
      font-size: 40px; /* Adjust font size for readability */
      margin-bottom: 15px; /* Reduce margin for better spacing */
    }
  
    .hero-subtitle {
      font-size: 15px; /* Adjust font size for better readability */
      margin-bottom: 25px; /* Reduce margin for better spacing */
    }
  
    .hero-button {
      width: 140px; /* Adjust width for consistency */
      height: 45px; /* Adjust height for consistency */
      font-size: 15px; /* Adjust font size for readability */
    }
  
    .hero-image {
      max-width: 350px; /* Adjust maximum width */
      height: 350px; /* Adjust height */
    }
  
    .logo-background {
      width: 500px; /* Adjust width */
      height: 500px; /* Adjust height */
      top: 40px; /* Adjust position for mid-sized screens */
      right: 0; /* Adjust position for mid-sized screens */
    }
  }
  
  @media (max-width: 1024px) {
    .hero-section {
      padding: 40px;
      flex-direction: column; /* Stack content vertically */
      text-align: center;
    }
  
    .hero-title {
      font-size: 36px;
    }
  
    .hero-button {
      width: 140px;
      height: 45px;
    }
  
    .hero-image {
      max-width: 300px;
      height: 300px;
    }
  
    .logo-background {
      width: 450px;
      height: 450px;
      top: 0px; /* Adjust position for smaller screens */
      right: 0px; /* Adjust position for smaller screens */
      left: 0px;
    }
  }
  
  @media (max-width: 768px) {
    .hero-section {
      padding: 30px;
    }
  
    .hero-title {
      font-size: 28px;
    }
  
    .hero-subtitle {
      font-size: 14px;
      margin-bottom: 20px;
    }
  
    .hero-button {
      width: 130px;
      height: 40px;
      font-size: 14px;
    }
  
    .hero-image {
      max-width: 250px;
      height: 250px;
    }
  
    .logo-background {
      width: 350px;
      height: 350px;
      top: 30px; /* Adjust position for smaller screens */
      right: -30px; /* Adjust position for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .hero-section {
      padding: 20px;
    }
  
    .hero-title {
      font-size: 24px;
    }
  
    .hero-subtitle {
      font-size: 12px;
    }
  
    .hero-button {
      width: 120px;
      height: 35px;
      font-size: 12px;
    }
  
    .hero-image {
      max-width: 200px;
      height: 200px;
    }
  
    .logo-background {
      width: 215px;
      height: 300px;
      top: 20px; /* Adjust position for smaller screens */
      right: -50px; /* Adjust position for smaller screens */
    }
  }
  