.services {
  padding: 50px;
  background-color: #F0F4F8;
  color: #1B1C3B;
  margin-top: 50px;
}

.services-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 60px;
  width: 100%;
  margin: 0 5px; /* Center the section */
}

.services-text,
.services-paragraph {
  width: 50%;
  text-align: left;
  margin-bottom: 40px;
}

.services-text h3 {
  font-size: 20px;
  color: #E84038;
  margin-bottom: 15px;
}

.services-text h2 {
  font-size: 28px;
  color: #1B1C3B;
  margin-bottom: 20px;
  margin-top: 60px;
}

.services-paragraph p {
  font-size: 28px;
  color: #555;
  line-height: 1.6;
}

.services-divider {
  border: 1px solid #E84038;
  margin: 100px 0;
}

.service-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin-top: 40px;
  gap: 20px;
}

.service-content {
  width: 50%;
  padding-right: 20px;
}

.service-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 40px;
}

.service-header-container h2 {
  font-size: 32px;
  color: #1B1C3B;
  margin-bottom: 20px;
}

.service-header-container p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-left: 30px;
}

.service-card-content {
  width: 40%;
  background-color: #3E2D79;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
}

.service-card-content ul {
  padding: 0;
  margin: 0;
}

.service-card-content li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}

.service-card-content li::before {
  content: '-';
  position: absolute;
  left: 0;
  font-size: 34px;
  color: #E84038;
}

.service-card-content h2 {
  font-size: 24px;
  color: #F5F5F5;
  margin: 20px 0;
}

.service-card-content button {
  font-size: 18px;
  color: #F5F5F5;
  background-color: #ec2446;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
}

.service-card-content button:hover {
  background-color: #F5F5F5;
  color: #ec2446;
}

.service-content h4 {
  font-size: 24px;
  color: #1B1C3B;
  margin-bottom: 20px;
}

.service-content p {
  font-size: 20px;
  color: #555;
  line-height: 1.6;
}

.icon-container {
  background-color: #FF5A5F;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container svg {
  width: 30px;
  height: 30px;
}

.icon-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

/* Media Queries */

@media (max-width: 1024px) {
  .services-section {
    flex-direction: column;
    gap: 20px;
  }

  .services-text,
  .services-paragraph {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .service-block {
    flex-direction: column;
    align-items: center;
  }

  .service-content,
  .service-card-content {
    width: 100%;
    padding-right: 0;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .services {
    padding: 30px;
  }

  .services-text h2,
  .services-paragraph p {
    font-size: 24px;
  }

  .service-header-container h2 {
    font-size: 28px;
  }

  .service-card-content {
    padding: 30px;
  }

  .service-card-content h2 {
    font-size: 20px;
  }

  .service-card-content button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .services-text h2,
  .services-paragraph p {
    font-size: 20px;
  }

  .service-header-container h2 {
    font-size: 24px;
  }

  .icon-container {
    width: 50px;
    height: 50px;
  }

  .icon-container svg {
    width: 25px;
    height: 25px;
  }
}
