
.blog-page {
    text-align: left;
    color: #EBF7FE;
    background-color: #F0F4F8;
  }

.hero {
    padding: 150px 50px;
    background-color: #1A1A2E;
    position: relative;
  }

  .hero h1 {
    font-size: 48px;
    color: #F5F5F5;
    width: 20%;
  }
  .hero p {
    font-size: 18px;
    color: #CCCCCC;
    margin: 20px 0;
    width: 40%;
  }

/* Rotating Image */
.rotating-image {
    position: absolute;
    bottom: 2px;
    right: 45px;
    width: 584px;
    height: auto;
    transform: rotate(-25deg);
    opacity: 0.5;
  }