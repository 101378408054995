/* faq-about Section */
.faq-about {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #1A1A2E;
    gap: 40px;
    align-items: flex-start;
  }

  .faq-about-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 60px;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .faq-about-texts {
    width: 100%;
    max-width: 1200px;
    text-align: left;
    margin-bottom: 40px;
  }
  
  .faq-about h3 {
    font-size: 18px;
    color: #E84038;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .faq-about h2 {
    font-size: 42px;
    color: white;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  
  .faq-about p {
    font-size: 18px;
    color: white;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .faq-about-link {
    width: 90px;
    font-size: 18px;
    color: #555;
    border-bottom: 1px solid #555;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    padding-bottom: 2px;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  
  .faq-about-link:hover {
    color: #E84038;
    border-color: #E84038;
  }
  
  /* Customize Ant Design Collapse */
  .custom-about-collapse {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 40px;
    background-color: transparent;
  }
  
  .custom-about-panel {
    border: none;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    border:#EBF7FE;
  }
  
  .custom-about-panel .ant-collapse-header {
    background-color: white;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 20px;
    transition: background-color 0.3s ease;
  }
  
  .custom-about-panel .ant-collapse-header:hover {
    background-color: #4E2A84;
    color: #F5F5F5;
  }
  
  .custom-about-panel .ant-collapse-content {
    background-color: white;
    border-radius: 5px;
  }
  
  .custom-about-panel .ant-collapse-content p {
    color: #F5F5F5;
    font-size: 16px;
    padding: 20px;
  }
  
  .custom-icon-container {
    background-color: #ec2446;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .custom-icon-container.active {
    transform: rotate(90deg);
  }
  
  .custom-icon {
    color: #EBF7FE;
    font-size: 16px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .faq-about {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .faq-about-texts,
    .custom-collapse {
      width: 100%;
    }
  
    .faq-about h2 {
      font-size: 36px;
      text-align: center;
    }
  
    .faq-about h3,
    .faq-about p,
    .faq-about-link {
      text-align: center;
    }
  
    .faq-about h3 {
      font-size: 16px;
    }
  
    .faq-about p {
      font-size: 16px;
    }
  
    .faq-about-link {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    .faq-about {
      padding: 15px;
    }
  
    .faq-about h2 {
      font-size: 32px;
    }
  
    .faq-about h3 {
      font-size: 14px;
    }
  
    .faq-about p {
      font-size: 14px;
    }
  
    .faq-about-link {
      font-size: 14px;
    }
  
    .custom-about-panel .ant-collapse-header {
      font-size: 16px;
      padding: 10px 15px;
    }
  
    .custom-about-panel .ant-collapse-content p {
      font-size: 14px;
      padding: 15px;
    }
  
    .custom-icon-container {
      width: 25px;
      height: 25px;
    }
  
    .custom-icon {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .faq-about h2 {
      font-size: 28px;
    }
  
    .faq-about h3 {
      font-size: 12px;
    }
  
    .faq-about p {
      font-size: 12px;
    }
  
    .faq-about-link {
      font-size: 12px;
    }
  
    .custom-about-panel .ant-collapse-header {
      font-size: 14px;
      padding: 8px 12px;
    }
  
    .custom-about-panel .ant-collapse-content p {
      font-size: 12px;
      padding: 10px;
    }
  
    .custom-icon-container {
      width: 20px;
      height: 20px;
    }
  
    .custom-icon {
      font-size: 12px;
    }
  }
  