/* About Us Section */

.our-story {
    padding: 50px ;
    background-color: #F0F4F8;
    color: #1B1C3B;
    margin-top: 50px;
  }
  
  /* .about-us-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 60px;
  } */

  .our-story-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 60px;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .our-story-text {
    width: 100%;
    max-width: 1200px;
    text-align: left;
    margin-bottom: 40px;
  }
  .our-story-paragraph {
    width: 100%;
    max-width: 1200px;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 15px;
  }
  
  .our-story-text h3 {
    font-size: 20px;
    color: #E84038;
    margin-bottom: 15px;
  }
  
  .our-story-text h2 {
    font-size: 48px;
    color: #1B1C3B;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  
  .our-story-paragraph p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
/* Cards Layout */
/* .about-us-cards {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
  }
   */

  
  @media (max-width: 768px) {
    .our-story-text h2 {
      font-size: 36px;
    }
  
    .our-story-text p {
      font-size: 16px;
    }
  
  
  }
  
  @media (max-width: 480px) {
    .our-story-text h2 {
      font-size: 28px;
    }
  
   
  }