.blog {
  padding: 40px;
  margin: 20px 0 0 20px;
  background-color: #f0f4f8;
}

/* Top Articles Section */
.top-articles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.top-article-card {
  background-color: white;
  width: 48%;
  height: 650px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.thumbnail {
  width: 100%;
  height: 400px;
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.article-info {
  text-align: left;
}

.category {
  color: #6c757d;
  font-size: 24px;
  margin-bottom: 10px;
}

.title {
  color: #21253b;
  font-size: 40px;
  margin-bottom: 10px;
}

.date {
  color: #6c757d;
  font-size: 24px;
}

/* Grid of Blog Cards */
.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 40px;
}

.blog-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: auto; /* Remove fixed height to allow flexibility */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.blog-card-thumbnail {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 15px;
  background-position: center; /* Ensure the image is centered */
}

.blog-card-category {
  color: #6c757d;
  font-size: 18px;
  margin-bottom: 10px;
}

.blog-card-title {
  color: #21253b;
  font-size: 30px;
  margin-bottom: 10px;
}

.blog-card-date {
  color: #6c757d;
  font-size: 18px;
}

.load-more-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  margin: 20px 0;
}

/* Load More Button */
.load-more-button {
  background-color: white;
  color: #E84038;
  font-weight: bold;
  font-size: large;
  width: 150px;
  height: 50px;
  border: none;
  text-align: center;
}

/* Newsletter Section */
.newsletter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.newsletter span {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.newsletter h2 {
  font-size: 44px;
  color: #21253b;
  flex: 1 1 auto; /* Allows flexibility in size */
  margin-right: 20px; /* Space between heading and input fields */
  white-space: nowrap; /* Prevents the text from breaking into multiple lines */
}

.email-input {
  width: 425px;
  height: 50px;
  margin-right: 20px;
  flex: 1; /* Takes up remaining space */
}

.subscribe-button {
  background-color: white;
  color: #E84038;
  font-weight: bold;
  font-size: large;
  width: 150px;
  height: 50px;
  border: none;
  flex-shrink: 0; /* Prevents shrinking of button */
}

/* Divider Styling */
.blog-divider {
  border: 1px solid #E84038;
  margin: 100px 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .top-articles {
    flex-direction: column;
  }

  .top-article-card {
    width: 100%;
  }


  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  .category, .date {
    font-size: 16px;
  }

  .blog-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .blog-card {
    height: auto;
  }

  .newsletter {
    flex-direction: column;
    align-items: flex-start;
  }

  .newsletter h2 {
    font-size: 34px;
    margin-bottom: 10px;
    margin-right: 0; /* Reset margin for smaller screens */
  }

  .newsletter span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .email-input {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .subscribe-button {
    width: 100%;
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 24px;
  }

  .top-article-card, .blog-card {
    padding: 15px;
  }

  .newsletter {
    flex-direction: column;
    align-items: center;
  }
  .newsletter h2 {
    font-size: 28px;
  }
  .newsletter span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .email-input {
    margin-bottom: 15px;
    margin-right: 0px;
    width: 100%;
    height: 35px;
  }
  .subscribe-button {
    width: 100%;
    height: 35px;
    max-width: 150px;
  }
  .subscribe-button span {
    font-size: 14px;
  }
  
}
