.services-page {
  text-align: left;
  color: #EBF7FE;
  background-color: #F0F4F8;
}

.hero {
  padding: 150px 50px;
  background-color: #1A1A2E;
  position: relative;
}

.hero h1 {
  font-size: 48px;
  color: #F5F5F5;
  width: 20%;
}

.hero p {
  font-size: 18px;
  color: #CCCCCC;
  margin: 20px 0;
  width: 40%;
}

.rotating-image {
  position: absolute;
  bottom: 2px;
  right: 45px;
  width: 584px;
  height: auto;
  transform: rotate(-25deg);
  opacity: 0.5;
}

/* Media Queries for Responsive Design */

@media (max-width: 1200px) {
  .hero h1 {
    font-size: 36px;
    width: 100%;
  }

  .hero p {
    font-size: 16px;
    width: 100%;
  }

  .rotating-image {
    width: 400px;
    right: 40px;
  }
}

@media (max-width: 1024px) {
  .hero h1 {
    font-size: 36px;
    width: 100%;
  }

  .hero p {
    font-size: 16px;
    width: 100%;
  }

  .rotating-image {
    width: 400px;
    right: 40px;
  }
}
@media (max-width: 768px) {
  .hero {
    padding: 100px 20px;
  }

  .rotating-image {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 28px;
  }

  .hero p {
    font-size: 14px;
  }

  .rotating-image {
    width: 60%;
  }
}
