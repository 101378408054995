/* Base Styles */
.blog-section {
  background-color: #F0F4F8;
  padding: 40px;
}

.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.blog-header h3 {
  font-size: 18px;
  color: #E84038;
  margin-bottom: 35px;
  width: 30%;
}

.blog-header h2 {
  width: 70%;
  font-size: 38px;
  color: #1B1C3B;
  margin-bottom: 20px;
}

.discover-button {
  background-color: white;
  color: #E84038;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid white;
  text-decoration: underline;
  transition: all 0.3s ease;
}

.discover-button:hover {
  background-color: #E84038;
  color: white;
  border-color: #E84038;
}

.blog-articles {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add gap to control spacing between cards */
}

.blog-section-card {
  background-color: white;
  width: 30%;
  height: 400px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 60px 8px;
}

.image-placeholder {
  background-color: #d0d0d0;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.blog-info {
  text-align: left;
}

.category {
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 10px;
}

.blog-info .title {
  color: #21253b;
  font-size: 20px;
  margin-bottom: 10px;
}

.blog-info .date {
  color: #6c757d;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .blog-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .blog-header h3, .blog-header h2 {
    width: 100%;
    margin-bottom: 10px;
  }

  .blog-articles {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .blog-section-card {
    width: 45%;
    margin: 20px 8px; /* Reduced margin for smaller screens */
  }
}

@media (max-width: 768px) {
  .blog-section {
    padding: 30px;
  }

  .blog-header {
    padding: 0 10px;
  }

  .blog-header h3, .blog-header h2 {
    font-size: 24px;
    width: 100%;
    margin-bottom: 20px;
  }

  .blog-articles {
    flex-direction: column;
    align-items: center;
  }
  
  .blog-section-card {
    width: 80%;
    margin: 20px 0; /* Reduced margin for more compact spacing */
  }

  .discover-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .blog-header h2 {
    font-size: 18px;
  }
  
  .blog-section-card {
    width: 100%;
    margin: 20px 0;
  }
  
  .blog-info .title {
    font-size: 18px;
  }
  
  .blog-info .date, .category {
    font-size: 12px;
  }

  .discover-button {
    padding: 6px 12px;
  }
}
