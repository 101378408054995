.custom-layout {
  background-color: #F0F4F8;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #0F3460;
}

.logo {
  color: #E94560;
  font-size: 24px;
  font-weight: bold;
}

.contact-button {
  background-color: #E94560;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #E84038;
}

.custom-content {
  background-color: #1B1C3B;
  padding: 1px;
  min-height: 80vh;
}


.custom-footer {
  background-color: #1A1A2E; /* Dark blue, matching the image */
  padding: 50px 20px; /* Adjusted padding for smaller screens */
  color: #CCCCCC;
  font-family: 'Poppins', sans-serif; /* Matching font */
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
  color: #EBF7FE; /* White color */
  margin-bottom: 10px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Add some space between columns on small screens */
}

.footer-title {
  font-size: 16px;
  font-weight: bold;
  color: #E94560 !important; /* Red, matching the image */
  margin-bottom: 10px;
}

.footer-links div {
  margin-bottom: 8px;
  color: #CCCCCC;
  font-size: 14px;
  line-height: 1.5;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom hr {
  border: none;
  border-top: 1px solid #E94560; /* Red line above the copyright */
  margin-bottom: 20px;
  width: 1100px;
}

.footer-bottom-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px; /* Add some gap between items */
}

.footer-bottom p {
  font-size: 14px;
  color: #CCCCCC;
  margin: 0;
}

.footer-bottom-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.footer-bottom-links span {
  font-size: 14px;
  color: #CCCCCC;
  cursor: pointer;
}
@media (max-width: 1024px) {
  
  .footer-bottom hr {
    border: none;
    border-top: 1px solid #E94560; /* Red line above the copyright */
    margin-bottom: 20px;
    width: 985px;
  }
}
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-links {
    align-items: center;
  }

  .footer-bottom hr {
    border: none;
    border-top: 1px solid #E94560; /* Red line above the copyright */
    margin-bottom: 20px;
    width: 300px;
  }

  .footer-bottom-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px; /* Ensure spacing between lines */
  }

  .footer-bottom-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 480px) {

  .custom-footer {
    padding: 30px 10px; /* Further reduce padding on very small screens */
  }

  .footer-logo {
    font-size: 20px;
  }

  .footer-title {
    font-size: 14px;
  }

  .footer-bottom hr {
    border: none;
    border-top: 1px solid #E94560; /* Red line above the copyright */
    margin-bottom: 20px;
    width: 250px;
  }

  .footer-links div {
    font-size: 12px;
  }

  .footer-bottom p,
  .footer-bottom-links span {
    font-size: 12px;
  }
}
