/* Base styles for larger screens */
.custom-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1A1A2E;
  padding: 20px 50px;
}
/* 
.logo {
  background: #EBF7FE;
  border-radius: 8%;
  width: 100px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.logo img {
  max-width: 100%;
  height: auto;
  display: block;
} */

.logo {
  background: #EBF7FE;
  border-radius: 8%;
  width: 100px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  transition: background-color 0.3s ease;
}

.logo:hover {
  background: #E84038;
  border-radius: 8%;
  width: 100px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  transition: background-color 0.3s ease;
}

.logo-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.hover-logo-image {
  max-width: 100%;
  height: auto;
  display: none;
}

.logo:hover .logo-image {
  display: none;
}

.logo:hover .hover-logo-image {
  display: block;
}


.nav-items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: center;
}

.nav-items li {
  margin: 0 20px;
}

.nav-items li a {
  color: #EBF7FE;
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-items li a:hover {
  color: #E84038;
}

.language-button {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.language-button svg {
  width: 30px;
  height: auto;
  margin-right: 5px;
}

.contact-button {
  background-color: #ec2446;
  color: white;
  padding: 10px 20px;
  border-radius: 8%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Add space between language icon and button */
}

.contact-button:hover {
  background-color: #E84038;
}

.nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Styles for the hamburger menu icon */
.nav-hamburger {
  display: none; /* Hide on larger screens */
}

.hamburger-icon {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.ant-drawer .ant-drawer-close {
  color: white;
  position: relative;
  top: 0px;
  right: 0px;
  left: 308px;
}

.ant-drawer .ant-drawer-title {
  position: relative;
  top: 0px;
  right: 0px;
  left: -30px;
}


/* Responsive styles */
@media (max-width: 768px) {
  .custom-nav {
    justify-content: space-between;
    padding: 10px 20px;
  }
  .logo {
    width: 80px;
    padding: 5px;
  }

  .logo:hover {
    width: 80px;
    padding: 5px;
  }

  /* .logo-image {
    width: 80px;
    padding: 5px;
  }
  .hover-logo-image {
    width: 80px;
    padding: 5px;
  } */

  .contact-button {
    font-size: 14px;
    width: 300px;
    padding: 8px 15px;
    position: relative;
    top: 550px;
    right: 5px;
  }

  .nav-items,
  .nav-right {
    display: none; /* Hide navbar items on smaller screens */
  }

  .nav-hamburger {
    display: block; /* Show hamburger icon on smaller screens */
  }
}

@media (max-width: 480px) {
  .custom-nav {
    padding: 10px 15px;
  }

  .logo {
    width: 60px;
  }

  .nav-items li a {
    font-size: 12px;
  }

  .language-button svg {
    width: 25px;
  }

  .contact-button {
    font-size: 14px;
    width: 300px;
    padding: 8px 15px;
    position: relative;
    top: calc(50vh - 50px); /* 50% of viewport height minus half the button's height */
    right: 5px;
}
}

@media (min-width: 240px) and (max-height: 667px) {
  .contact-button {
      top: calc(40vh - 50px); /* Slightly lower for smaller screens */
  }
}
@media (min-width: 414px) and (max-height: 960px) {
  .contact-button {
      top: calc(65vh - 50px); /* Lower the button further for larger screens */
  }
}

/* Drawer specific styles */
.nav-drawer {
  background-color: #1A1A2E; /* Background color for the drawer */
}

.nav-drawer .logo {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.nav-drawer .nav-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-drawer .nav-items li {
  margin: 10px 0;
}

.nav-drawer .nav-items li a {
  color: #EBF7FE;
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-drawer .nav-items li a:hover {
  color: #E84038;
}

.nav-drawer .nav-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
