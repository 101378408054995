.contact-us-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 50px;
  background-color: #F0F4F8;
  gap: 20px;
}

.contact-us-title {
  font-size: 38px;
  color: #1B1C3B;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-info-item {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 300px;
  height: 100px;
}

.contact-icon {
  font-size: 36px;
  color: #F34336;
  margin-right: 20px;
}

.contact-title {
  font-size: 16px;
  color: #F34336;
  font-weight: bold;
  text-transform: uppercase;
}

.contact-detail {
  font-size: 16px;
  color: #333333;
  margin-top: 5px;
}

.contact-form {
  width: 400px;
  padding: 20px;
  border-radius: 5px;
  background-color: #EBF7FE;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
}

.form-select {
  margin-bottom: 20px;
  width: 100%;
}

.form-input, .form-textarea {
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.form-select .ant-select-selector {
  padding: 10px;
  border-radius: 5px;
}

.submit-button {
  background-color: #F34336;
  border-color: #F34336;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.submit-button:hover {
  background-color: #FF5A49 !important;
  border-color: #FF5A49;
  color: #EBF7FE !important;
}
/* Responsive Adjustments */
@media (max-width: 1024px) {
  .contact-us-container {
    padding: 30px;
    flex-wrap: nowrap;
  }

  .contact-info-item,
  .contact-form {
    width: 100%;
  }

  .contact-us-title {
    font-size: 32px;
    text-align: center;

  }

  .form-title {
    font-size: 22px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .contact-us-container {
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .contact-info {
    width: 100%;
    align-items: center;
  }

  .contact-info-item {
    width: 90%;
    height: auto;
    padding: 15px;
    text-align: center;
    flex-direction: column;
  }

  .contact-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .contact-title,
  .contact-detail {
    text-align: center;
  }

  .contact-form {
    width: 90%;
  }

  .contact-us-title {
    font-size: 28px;
  }

  .form-title {
    font-size: 20px;
  }

  .form-input, .form-textarea {
    padding: 8px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .contact-us-container {
    padding: 15px;
  }

  .contact-us-title {
    font-size: 24px;
  }

  .form-title {
    font-size: 18px;
  }

  .contact-info-item {
    padding: 10px;
    height: auto;
  }

  .contact-icon {
    font-size: 28px;
  }

  .contact-title,
  .contact-detail {
    font-size: 14px;
  }

  .form-input, .form-textarea {
    font-size: 14px;
  }

  .submit-button {
    font-size: 12px;
    padding: 8px;
  }
}
