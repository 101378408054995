/* Pricing Section */

.pricing {
    display: flex;
    flex-direction: column;
    background-color: #1B1C3B;
    gap: 40px;
    padding: 60px;
    
  }

  .pricing.large {
    height: 2500px;
  }
  
  .pricing div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 22px auto;
    gap: 20px;
  }
  
  .pricing span {
    width: 40%;
    text-align: left;
  }
  
  .pricing h3 {
    font-size: 32px;
    color: #E84038;
    margin-bottom: 15px;
    width: 20%;
  }
  
  .pricing h2 {
    font-size: 38px;
    color: #F5F5F5;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .pricing p {
    font-size: 26px;
    color: #F5F5F5;
    margin-bottom: 20px;
  }
  
  .pricing img {
    width: 40%;
    border-radius: 8px;
  }


  /* Pricing Sub Card Styles */
.pricing-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px auto;
    background-color: #F0F4F8;
    border-radius: 30px;
    width: 90%;
    height: 500px;
    padding: 60px;
    position: relative;
  }
  
  .pricing-sub span {
    width: 50%;
  }
  
  .pricing-sub h2 {
    font-size: 38px;
    color: #1B1C3B;
    margin-bottom: 40px;
  }
  
  .pricing-sub h3 {
    font-size: 18px;
    color: #E84038;
    margin-bottom: 35px;
  }
  
  .pricing-sub button {
    font-size: 18px;
    color: #F5F5F5;
    background-color: #E84038;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .pricing-sub button:hover {
    background-color: #1B1C3B;
    color: #E84038;
  }
  
  .pricing-sub img {
    position: absolute;
    bottom: 55px;
    right: 100px;
    width: 84px;
    height: auto;
    filter: brightness(0) saturate(100%) invert(13%) sepia(11%) saturate(732%) hue-rotate(186deg) brightness(99%) contrast(103%);
  }

 /* Media Queries for Responsive Design */
 @media (max-width: 1200px) {
    .pricing.large {
      height: 3000px; /* Adjusted height for better spacing */
    }
    
    .pricing div {
      flex-direction: column;
      gap: 20px;
    }
  
    .pricing img {
      width: 100%;
      order: 2; /* Ensures image comes after text */
    }
  
    .pricing span {
      width: 100%;
      order: 1; /* Ensures text comes before image */
      text-align: center; /* Center text alignment */
    }
  
    .pricing-sub {
      flex-direction: column;
      align-items: center;
      padding: 40px;
      height: auto; /* Adjust height */
      text-align: center; /* Center text inside .pricing-sub */
    }
  
    .pricing-sub span {
      width: 100%; /* Full width for centering */
      max-width: 800px; /* Optional: to limit the maximum width */
      text-align: left; /* Center text inside span */
    }
  
    .pricing-sub h2 {
      margin-bottom: 20px; /* Adjust margin as needed */
    }
  
    .pricing-sub img {
      position: relative;
      bottom: 0;
      left: 375px; /* Adjust position as needed */
      margin-top: 20px;
      width: 12%; /* Ensure image is responsive */
    }
  }
  
  @media (max-width: 1024px) {
    .pricing.large {
      height: 3000px; /* Adjusted height for better spacing */
    }
    
    .pricing div {
      flex-direction: column;
      gap: 20px;
    }
  
    .pricing img {
      width: 100%;
      order: 2; /* Ensures image comes after text */
    }
  
    .pricing span {
      width: 100%;
      order: 1; /* Ensures text comes before image */
      text-align: center; /* Center text alignment */
    }
  
    .pricing-sub {
      flex-direction: column;
      align-items: center;
      padding: 40px;
      height: auto; /* Adjust height */
      text-align: center; /* Center text inside .pricing-sub */
    }
  
    .pricing-sub span {
      width: 100%; /* Full width for centering */
      max-width: 800px; /* Optional: to limit the maximum width */
      text-align: left; /* Center text inside span */
    }
  
    .pricing-sub h2 {
      margin-bottom: 20px; /* Adjust margin as needed */
    }
  
    .pricing-sub img {
      position: relative;
      bottom: 0;
      left: 330px; /* Adjust position as needed */
      margin-top: 20px;
      width: 12%; /* Ensure image is responsive */
    }
  }
  
  
  @media (max-width: 768px) {
    .pricing div {
      flex-direction: column;
      gap: 20px;
    }
  
    .pricing img {
      width: 100%;
      order: 2; /* Ensures image comes after text */
    }
  
    .pricing span {
      width: 100%;
      order: 1; /* Ensures text comes before image */
    }
  
   .pricing-sub {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    height: auto; /* Adjust height */
    margin-bottom: 50px;
  }

  .pricing-sub span {
    width: 100%;
    text-align: center;
  }

  .pricing-sub img {
    position: relative;
    bottom: 0;
    left: 195px;
    margin-top: 20px;
    width: 20%;
  }

  .pricing-sub h2,
  .pricing-sub h3 {
    text-align: center;
    width: 100%;
  }
  .pricing-sub button {
    text-align: center;
    width: 60%;
  }
  }
  
  @media (max-width: 480px) {
    .pricing {
      padding: 40px;
    }
    
    .pricing.large {
      height: 1800px; /* Adjusted height for better spacing */
    }
    .pricing div {
      flex-direction: column;
      gap: 20px;
    }
  
    .pricing img {
      width: 100%;
      order: 2; /* Ensures image comes after text */
    }
  
    .pricing span {
      width: 100%;
      order: 1; /* Ensures text comes before image */
    }
    .pricing-item h3 {
      font-size: 24px;
    }
    .pricing-item h2 {
      font-size: 24px;
    }
    .pricing-item p {
      font-size: 15px;
    }
  
    .pricing-sub {
        padding: 20px;
        flex-direction: column;
        align-items: center;
        height: auto; /* Adjust height */
      }
    
      .pricing-sub span {
        width: 100%;
        text-align: center;
      }
    
      .pricing-sub h2,
      .pricing-sub h3,
      .pricing-sub button {
        text-align: center;
        width: 100%;
      }

      .pricing-sub h2 {
        font-size: 24px;
      }
    
      .pricing-sub img {
        position: relative;
        bottom: 8px;
        left: 0;
        margin-top: 20px;
        width: 34%;
      }
  }
  