/* About Us Section */

.about-us {
    padding: 50px ;
    background-color: #F0F4F8;
    color: #1B1C3B;
    margin-top: 50px;
  }
  
  /* .about-us-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 60px;
  } */

  .about-us-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 60px;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .about-us-text {
    width: 100%;
    max-width: 600px;
    text-align: left;
    margin-bottom: 40px;
  }
  .about-us-paragraph {
    width: 100%;
    max-width: 600px;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 80px;
  }
  
  .about-us-text h3 {
    font-size: 20px;
    color: #E84038;
    margin-bottom: 15px;
  }
  
  .about-us-text h2 {
    font-size: 48px;
    color: #1B1C3B;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  
  .about-us-text p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
/* Cards Layout */
/* .about-us-cards {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
  }
   */


  /* Cards Layout */
.about-us-cards {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  /* First card larger and spanning two rows */
  .card:first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: #ccc;
  }
  
  /* Other cards in the grid */
  .card {
    border-radius: 8px;
    background-color: #ccc;
    overflow: hidden;
  }
  
  /* Make images responsive */
  .card img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .about-us-text h2 {
      font-size: 36px;
    }
  
    .about-us-text p {
      font-size: 16px;
    }
  
    .about-us-cards {
      grid-template-columns: 1fr;
    }
  
    .card:first-child {
      grid-row: 1 / 2;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-text h2 {
      font-size: 28px;
    }
  
    .about-us-cards {
      grid-template-columns: 1fr;
    }
  
    .card:first-child {
      grid-row: 1 / 2;
      height: auto;
    }

    .about-us-text {
      width: 100%;
      max-width: 600px;
      text-align: left;
    }

    .about-us-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      width: 100%;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }
  }