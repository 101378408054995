/* Base Styles */
.homepage {
  text-align: left;
  color: #EBF7FE;
  background-color: #F0F4F8;
}

.home-hero {
  padding: 150px 50px;
  background-color: #1A1A2E;
  position: relative;
}

.home-hero h1 {
  font-size: 48px;
  color: #F5F5F5;
  width: 20%;
}

.home-hero h3 {
  font-size: 18px;
  color: #E84038;
  margin-bottom: 15px;
  width: 20%;
}

.home-hero p {
  font-size: 18px;
  color: #CCCCCC;
  margin: 20px 0;
  width: 40%;
}

.home-hero span {
  font-size: 18px;
  color: #CCCCCC;
  margin: 20px 0px 0px 40px;
  border-bottom: 1px solid white;
}

.primary-button {
  background-color: #ec2446;
  border: none;
  border-radius: 8%;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.primary-button:hover {
  background-color: #E84038;
}

.home-rotating-image {
  position: absolute;
  bottom: 2px;
  right: 45px;
  width: 584px;
  height: auto;
  transform: rotate(-25deg);
  opacity: 0.5;
}

/* About Us Sections */
.home-about-us {
  padding: 60px 50px;
  background-color: #F0F4F8;
  color: #1B1C3B;
}

.home-about-us-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.home-about-us-section span {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.home-about-us-section img {
  width: 50%;
  height: auto;
  border-radius: 8px;
}

.home-about-us-section h3 {
  font-size: 20px;
  color: #E84038;
  margin-bottom: 15px;
  width: 20%;
}

.home-about-us-section h2 {
  font-size: 48px;
  color: #1B1C3B;
  margin-bottom: 20px;
}

.home-about-us-section h4 {
  font-size: 34px;
  color: #555;
  margin-bottom: 20px;
}

.home-about-us-section h5 {
  width: 100px;
  font-size: 18px;
  color: #555;
  margin: 20px 0px;
  border-bottom: 1px solid #555;
}

.home-about-us-section p {
  font-size: 28px;
  color: #555;
  line-height: 1.6;
}

/* Services Section */

.home-services-section {
  /* padding: 40px; */
  display: flex;
  flex-direction: column;
}

.home-services-section h2 {
  font-size: 38px;
  color: #1B1C3B;
  margin-bottom: 30px;
}

.home-services-section h3 {
  font-size: 20px;
  color: #E84038;
  margin-bottom: 15px;
  width: 30%;
}

.home-services-section-services {
  display: flex;
  justify-content: center; /* Center the divs */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 20px;
}

.home-services-section-services > div {
  background-color: #EBF7FE;
  border: 2px solid #1B1C3B;
  width: 400px;
  height: 500px;
  border-radius: 8px;
  padding: 50px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.icon-container {
  background-color: #FF5A5F;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0 20px;
}

.icon-container svg {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.home-services-section p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.home-services-section h5 {
  font-size: 16px;
  color: #555;
  margin: 20px 0px;
  border-bottom: 1px solid #555;
}

/* Media Queries */

@media (max-width: 1024px) {
  .home-hero h1 {
    font-size: 36px;
    width: 100%;
  }

  .home-hero p {
    font-size: 16px;
    width: 100%;
  }

  .home-rotating-image {
    width: 400px;
    right: 40px;
  }
}

@media (max-width: 768px) {
  .home-hero {
    padding: 80px 30px;
  }
  
  .home-hero h1,
  .home-hero p,
  .home-about-us-section h2,
  .home-about-us-section h4 {
    font-size: 24px;
  }

  .home-about-us-section {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
  }

  .home-about-us-section span {
    width: 100%;
  }

  .home-about-us-section img {
    width: 100%; /* Image takes full width */
  }

  .home-services-section-services {
    flex-direction: column; /* Stack service divs vertically */
    align-items: center; /* Center the service divs */
  }

  .home-services-section-services > div {
    width: 100%; /* Service divs take full width */
    max-width: 400px; /* Set a max width for consistency */
  }

  .home-hero span,
  .home-hero p {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .home-about-us {
    padding: 40px 20px;
  }
  .home-hero {
    padding: 60px 20px;
  }

  .home-hero h1,
  .home-hero p,
  .home-about-us-section h4 {
    font-size: 18px;
  }

  .home-about-us-section h2 {
    font-size: 10px;
  }
  .primary-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .home-about-us-section {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .home-services-section-services > div {
    width: 100%; /* Service divs take most of the width */
    margin: 0 auto; /* Center service divs */
  }

  .home-services-section-services h2 {
    width: 100%;
    font-size: 28px;
  }

  .home-rotating-image {
    width: 50%;
    right: 20px;
  }
}
