.language-button {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 24px; /* Adjust the icon size */
    color: #EBF7FE; /* Matches the white text color theme */
    transition: color 0.3s ease;
  }
  
  .language-button:hover {
    color: #ff2243; /* Matches the hover color theme */
  }
  
  .ant-dropdown-menu {
    background-color: #b2b2bd !important; /* Matches your custom nav background */
    border: none; /* Removes the border for a clean look */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* Adds a subtle shadow */
    width: 160px; /* Adjust the width to fit your content */
    height: 70px; /* Adjust the height to fit your content */
    position: relative;
    top: 10px; /* Adjust the top position to fit your content */
    right: 140px; /* Adjust the right position to fit your content */
  }
  
  .ant-dropdown-menu-item {
    color: #1A1A2E !important; /* White text color for menu items */
    font-size: 26px; /* Adjust font size for consistency */
    padding: 10px 20px;
    font-weight: 800 !important;
    transition: background-color 0.3s ease;
  }
  
  .ant-dropdown-menu-item:hover {
    background-color: #ff2243 !important; /* Matches hover color for menu items */
    color: #EBF7FE !important; /* Keeps text white on hover */
  }
  